import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref } from 'firebase/storage';



const firebaseConfig = {
  apiKey: "AIzaSyCsL71_IWorZIw83f3B0_X2AuKZCsD6X30",
  authDomain: "lab3-core-1.firebaseapp.com",
  projectId: "lab3-core-1",
  storageBucket: "lab3-core-1.appspot.com",
  messagingSenderId: "754337337539",
  appId: "1:754337337539:web:42e182b7eead350fa51276",
  measurementId: "G-KPPP2D53S3"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app)
export const storageRef = ref(storage);
export const auth = getAuth(app);

