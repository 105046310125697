import React, { useState, useEffect } from 'react';
import { Table, Container, Row, Col, Card, CardDeck, CardBody, Modal, ModalHeader, ModalBody, ModalFooter,  Nav, NavItem, NavLink, Navbar, Input, NavbarBrand, Button } from 'reactstrap';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import ReactIosSwitch from 'react-ios-switch';
import '../App.css';
import blueBot from '../images/blue-bot.png'
import { FaPencilAlt } from 'react-icons/fa';
import { db } from '../firebase'; // Path to your firebase.js file
import { collection, onSnapshot, getDocs, query, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import { InfinitySpin } from "react-loader-spinner";
import ShiftScheduler from './tools/ShiftScheduler';
import { Oval } from 'react-loader-spinner'
import 'bootstrap/dist/css/bootstrap.min.css';



function SpeechBubble({ defaultValue, label }) {
    const [textValue, setTextValue] = useState(defaultValue);
    const [isEditing, setIsEditing] = useState(false);

    return (
        <>
            <Card className="rounded-card">
                <small className="bold-text">{label}:</small> <br />
                {isEditing ? (
                    <>
                        <textarea
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                            // onBlur={() => setIsEditing(false)}
                            rows={4}
                            cols={20}
                            className="med-text rounded-card no-border"
                        />
                        <br />
                        <div className="text-center">
                            <Button color="primary" className="custom-button" onClick={() => setIsEditing(false)}>Save</Button>
                            <Button color="secondary" className="custom-button" onClick={() => setIsEditing(false)}>Cancel</Button>
                        </div>
                    </>
                ) : (
                    <div onClick={() => setIsEditing(true)}>
                        <span className="med-text">{textValue}</span>
                        <FaPencilAlt className="pencil-icon" />
                    </div>
                )}
            </Card>
        </>
    );
}





function Reception() {


    function SimpleCard({ title, description, fullDescription }) {
        const [showModal, setShowModal] = useState(false);
    
        const handleShowModal = () => setShowModal(true);
        const handleCloseModal = () => setShowModal(false);
    
        const modalStyles = {
            maxWidth: '900px', // Maximum width for large screens
            width: '80%', // Default width
        };
    
        // Define modal style for mobile view
        const modalMobileStyles = {
            ...modalStyles, // Spread the existing styles
            width: '90%', // Increase width for mobile screens
        };
    
    
        return (
            <>
                <Card body className="lab3-card" onClick={handleShowModal}>
                    <div className="lab3-card-content">
                        <h5 className="lab3-card-title">{title}</h5>
                        <p className="lab3-card-description">{description}</p>
                    </div>
                </Card>
    
                <Modal isOpen={showModal} toggle={handleCloseModal} className="lab3-modal">
                    <ModalHeader toggle={handleCloseModal} className="lab3-modal-header">Job Description</ModalHeader>
                    <ModalBody className="lab3-modal-body">
                        <div dangerouslySetInnerHTML={{ __html: fullDescription }} />
                    </ModalBody>
                    <ModalFooter className="lab3-modal-footer">
                        <Button color="primary" onClick={() => {/* handle apply action */}}>Apply</Button>
                        <Button color="secondary" onClick={handleCloseModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    return (
        <div className="App">
            
        </div>
    );
    
}

export default Reception;
