import React, { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc, getDoc, setDoc } from "@firebase/firestore";
import { db, storage } from "../firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./JobApplicationForm.css";

const JobApplicationForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    position: "",
    fullName: "",
    email: "",
    phone: "",
    country: "",
    resume: null,
    references: "",
    dob: "",
  };

  const [applicationData, setApplicationData] = useState(initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplicationData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setApplicationData((prev) => ({ ...prev, resume: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const resumeFileName = `resumes/${applicationData.fullName}_${Date.now()}.pdf`;
    const resumeRef = ref(storage, resumeFileName);

    try {
      await uploadBytes(resumeRef, applicationData.resume);
      const downloadUrl = await getDownloadURL(resumeRef);

      const applicantEntry = {
        ...applicationData,
        resume: downloadUrl,
      };

      const uniqueApplicantField = `${applicantEntry.fullName}_${Date.now()}`;
      const applicantsDocRef = doc(db, "JobApplicants", "applicants");
      const docSnap = await getDoc(applicantsDocRef);

      if (docSnap.exists()) {
        await updateDoc(applicantsDocRef, { [uniqueApplicantField]: applicantEntry });
      } else {
        await setDoc(applicantsDocRef, { [uniqueApplicantField]: applicantEntry });
      }

      toast.success("Application submitted successfully!");
      // Reset the form data to initial state after successful submission
      setApplicationData(initialState);
    } catch (error) {
      console.error("Error submitting application:", error);
      toast.error("There was an error submitting your application.");
    }
    setIsLoading(false);
  };

  return (
    <div className="formContainer">
      <form onSubmit={handleSubmit}>
        <h3 className="formHeading">Job Application</h3>
        <div>
          <label htmlFor="position" className="formLabel">
            Which position are you applying for?
          </label>
          <select id="position" name="position" value={applicationData.position} onChange={handleInputChange} className="formSelect" required>
            <option value="">Select a position</option>
            <option value="Mobile Developer">Mobile Developer</option>
            <option value="Spring/Summer Intern">Spring/Summer Intern</option>
          </select>
        </div>

        <div>
          <label htmlFor="fullName" className="formLabel">
            Full Name
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={applicationData.fullName}
            onChange={handleInputChange}
            className="formInput"
            required
          />
        </div>

        <div>
          <label htmlFor="dob" className="formLabel">
            Date of Birth
          </label>
          <input type="date" id="dob" name="dob" value={applicationData.dob} onChange={handleInputChange} className="formInput" required />
        </div>

        <div>
          <label htmlFor="email" className="formLabel">
            Email
          </label>
          <input type="email" id="email" name="email" value={applicationData.email} onChange={handleInputChange} className="formInput" required />
        </div>

        <div>
          <label htmlFor="phone" className="formLabel">
            Phone
          </label>
          <input type="tel" id="phone" name="phone" value={applicationData.phone} onChange={handleInputChange} className="formInput" required />
        </div>

        <div>
          <label htmlFor="country" className="formLabel">
            Country
          </label>
          <input
            type="text"
            id="country"
            name="country"
            value={applicationData.country}
            onChange={handleInputChange}
            className="formInput"
            required
          />
        </div>

        <div>
          <label htmlFor="resume" className="formLabel">
            Resume
          </label>
          <input type="file" id="resume" name="resume" accept=".pdf,.doc,.docx" onChange={handleFileChange} className="formInput" required />
        </div>

        <div>
          <label htmlFor="references" className="formLabel">
            References
          </label>
          <textarea
            id="references"
            name="references"
            value={applicationData.references}
            onChange={handleInputChange}
            className="formTextarea"
            required
          />
        </div>

        {/* Additional form fields as previously detailed */}
        <button className="formButton" type="submit" disabled={isLoading}>
          {isLoading ? "Submitting..." : "Submit Application"}
        </button>
      </form>
    </div>
  );
};

export default JobApplicationForm;
