import React, { useState, useEffect } from "react";
import {
  Table,
  Container,
  Row,
  Col,
  Card,
  CardDeck,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Input,
  NavbarBrand,
  Button,
} from "reactstrap";
import "./App.css";
import logo from "./images/lab3logo.png";
import Reception from "./components/Reception";
import JobApplicationForm from "./components/Jobapp";
import ChronoLogo from "./images/chrono-logo.svg";
import { db, auth } from "./firebase";
import axios from "axios";
import { InfinitySpin } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [showReception, setShowReception] = useState(true);
  const [showMarketing, setShowMarketing] = useState(false);
  const [showSales, setShowSales] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showJobApp, setShowJobApp] = useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = (type) => {
    setShowReception(type === "Reception");
    setShowMarketing(type === "Marketing");
    setShowSales(type === "Sales");
    setShowInventory(type === "Inventory");
    setShowHistory(type === "History");
    setShowJobApp(type === "Apply");
  };

  function SimpleCard({ title, description, fullDescription }) {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const modalStyles = {
      maxWidth: "900px", // Maximum width for large screens
      width: "80%", // Default width
    };

    // Define modal style for mobile view
    const modalMobileStyles = {
      ...modalStyles, // Spread the existing styles
      width: "90%", // Increase width for mobile screens
    };

    return (
      <>
        <Card body className="lab3-card" onClick={handleShowModal}>
          <div className="lab3-card-content">
            <h5 className="lab3-card-title">{title}</h5>
            <p className="lab3-card-description">{description}</p>
          </div>
        </Card>

        <Modal isOpen={showModal} toggle={handleCloseModal} className="lab3-modal">
          <ModalHeader toggle={handleCloseModal} className="lab3-modal-header">
            Job Description
          </ModalHeader>
          <ModalBody className="lab3-modal-body">
            <div dangerouslySetInnerHTML={{ __html: fullDescription }} />
          </ModalBody>
          <ModalFooter className="lab3-modal-footer">
            <Button
              color="primary"
              onClick={() => {
                setShowJobApp("Apply");
              }}
            >
              Apply
            </Button>
            <Button color="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="App">
        <Navbar expand="md" className="App-navbar">
          <NavbarBrand href="/">
            <img src={logo} alt="logo" className="App-logo" />
          </NavbarBrand>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="#" onClick={() => handleClick("Reception")}>
                <span className={showReception ? "bold-text rounded-card" : ""}> Careers</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={() => handleClick("Apply")}>
                <span className={showJobApp ? "bold-text rounded-card" : ""}> Apply</span>
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar></Nav>
        </Navbar>
        <Container>
          {showJobApp && (
            <div>
              <JobApplicationForm />
            </div>
          )}
          {showReception && (
            <div>
              <Container className="text-left">
                <br />
                <h2 className="bold-text text-black">Career Opportunities at Lab3</h2>
                <br />
                <CardDeck>
                  <SimpleCard
                    title="Mobile Developer"
                    description="Join our forward-thinking team at Lab3 as a Mobile Developer, specializing in Swift and React Native. You'll have the opportunity to impact the mobile app landscape by developing high-quality, innovative applications. This role is perfect for those passionate about leveraging the latest technologies to create seamless, user-friendly mobile experiences."
                    fullDescription="<p><strong>Position:</strong> Mobile Developer<br><strong>Company:</strong> Lab3<br><strong>Job Summary:</strong> Lab3 is seeking a talented Mobile Developer with expertise in Swift and React Native to join our dynamic team. This role focuses on crafting exceptional mobile applications by applying the latest development practices. You'll collaborate with cross-functional teams to design, develop, and launch innovative mobile solutions that meet our high standards for performance, quality, and usability.</p>
                    <p><strong>Key Responsibilities:</strong>
                    <ul>
                        <li>Develop and maintain high-quality mobile applications using Swift for iOS and React Native for cross-platform development.</li>
                        <li>Translate design and wireframes into high-quality code, ensuring the best possible performance, quality, and responsiveness of the application.</li>
                        <li>Work with the product team to define app features and create a seamless user experience.</li>
                        <li>Identify and correct bottlenecks, and fix bugs to optimize application performance.</li>
                        <li>Stay up-to-date with the latest mobile development trends and technologies to continuously improve our products.</li>
                        <li>Collaborate with cross-functional teams to integrate new features, test functionality, and gather feedback to refine and improve apps.</li>
                    </ul>
                    </p>
                    <p><strong>Technical Requirements:</strong>
                    <ul>
                        <li>Strong proficiency in Swift and a deep understanding of the iOS ecosystem.</li>
                        <li>Experience with React Native for developing cross-platform mobile applications.</li>
                        <li>Understanding of mobile app design guidelines (iOS Human Interface Guidelines and Material Design) and best practices.</li>
                        <li>Experience with third-party libraries and APIs, and the ability to integrate them into existing applications.</li>
                        <li>Knowledge of modern mobile development frameworks and tools, including version control systems like Git.</li>
                        <li>Familiarity with continuous integration and deployment (CI/CD) for mobile apps.</li>
                        <li>Proficient understanding of code versioning tools, such as Git.</li>
                        <li>Experience with automated testing and building testing suites for mobile applications.</li>
                    </ul>
                    </p>
                    <p><strong>Qualifications:</strong>
                    <ul>
                        <li>A Bachelor's degree in Computer Science, engineering, or a related field.</li>
                        <li>At least 3 years of experience in mobile development, with a portfolio of released applications on the App Store or the Android market.</li>
                        <li>Strong analytical and problem-solving skills, with a keen attention to detail.</li>
                        <li>Excellent communication and teamwork skills, with a proven ability to work in a fast-paced development environment.</li>
                    </ul>
                    Lab3 offers a STARTING salary of 35,000-60,000(DOE) and a dynamic work environment for all Mobile Developers. We value diversity and innovation and encourage all qualified candidates to apply.</p>"
                  />

                  <SimpleCard
                    title="Spring/Summer Software Development Intern"
                    description="Join Lab3's Spring/Summer Internship program and gain hands-on experience in software development. This paid internship is an excellent opportunity for college students to learn from experienced mentors, participate in real-world projects, and develop essential skills for their future careers."
                    fullDescription="<p><strong>Position:</strong> Spring/Summer Intern - Software Development<br><strong>Company:</strong> Lab3<br><strong>Job Summary:</strong> Lab3 is excited to offer a Spring/Summer Internship position for college students passionate about software development. This position starts with 90 days of paid training as an intern. Following the completion of the initial training period, interns will be eligible for a paid position with a salary range of 35,500BDT to 60,000BDT, dependent on experience and performance, with annual increases.</p>
                    <p><strong>Key Responsibilities:</strong>
                    <ul>
                    <li>Participate in the development and testing of software under the guidance of experienced mentors during the paid internship period, with a specific focus on ReactJS and NodeJS applications.</li>
                    <li>Assist in coding and debugging tasks, gaining a practical understanding of ReactJS and NodeJS in the software development lifecycle.</li>
                    <li>Collaborate with team members on existing projects, contributing to problem-solving and innovation, especially in ReactJS and NodeJS environments.</li>
                    <li>Engage in learning activities and workshops to enhance skills in ReactJS and NodeJS alongside general programming skills and understanding of software engineering principles.</li>
                    <li>Document and report on project progress and learnings, particularly in projects involving ReactJS and NodeJS.</li>
                    </ul>
                    </p>
                    <p><strong>Learning Opportunities:</strong>
                    <ul>
                    <li>Intensive hands-on experience with ReactJS and NodeJS, from basic coding to advanced application development.</li>
                    <li>Introduction to software development processes and methodologies with an emphasis on JavaScript ecosystems.</li>
                    <li>Exposure to problem-solving and debugging techniques in a real-world setting, specifically within ReactJS and NodeJS projects.</li>
                    <li>Opportunity to observe and understand the dynamics of working within a software development team focusing on modern web technologies.</li>
                    <li>Guidance on best practices in coding, documentation, and software design, with a special focus on ReactJS and NodeJS.</li>
                    </ul>
                    </p>
                    <p><strong>Qualifications:</strong>
                    <ul>
                    <li>Currently enrolled in a bachelor’s degree program in Computer Science, Software Engineering, or a related field.</li>
                    <li>Basic understanding of programming concepts with a keen interest in ReactJS and NodeJS.</li>
                    <li>Strong analytical and problem-solving skills.</li>
                    <li>Excellent communication skills and a willingness to work collaboratively.</li>
                    <li>Demonstrated interest in software development and technology, particularly in web technologies like ReactJS and NodeJS.</li>
                    </ul>
                    <p>This 3-month paid internship offers Bangladeshi college students a unique opportunity to dive deep into the tech industry with a US-based firm, develop crucial software development skills in ReactJS and NodeJS, and gain invaluable experience for their future careers.</p>"
                  />
                </CardDeck>
                <br />
                {/* Other components and code */}
              </Container>
            </div>
          )}
        </Container>
      </div>
    </>
  );
}

export default App;
